body {
    background: #1d1d22;
}

::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
    background: #1d1d22;
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px; /* Rounded corners for the scrollbar handle */
}

/* On hover, style the scrollbar handle */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
