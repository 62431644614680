.menu {
    position: fixed;
    width: 240px;
    background: #1a1921;
    padding: 20px 0;
    left: 10px;
    height: 100vh;
    text-align: center;
    color: white;
    left: -240px;
    border-radius: 14px;
    font-size: 16px;
    transition: all 0.5s ease-in-out;
    z-index: 999;
}

.menu a {
    color: white;
    text-decoration: none;
    opacity: 0.9;
}

.leaveMenuContainer {
    display: flex;
    justify-content: flex-end;
    margin: 5px 32px 22px 0;
}

.menu.open {
    left: 0px;
    transition: all 0.5s ease-in-out;
}

.hContent {
    display: flex;
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.hContent .hLeftSide .hSelect {
    padding: 10px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: #15121a;
    margin-right: 20px;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: column;
    cursor: pointer;
    display: none;
}

.hContent .hLeftSide .hSelect .line1 {
    height: 3px;
    width: 30px;
    background: #838383;
    border-radius: 14px;
}

.hContent .hLeftSide .hSelect .line2 {
    height: 3px;
    width: 24px;
    background: #838383;
    border-radius: 14px;
}

.hContent .hLeftSide .hSelect .line3 {
    height: 3px;
    width: 14px;
    background: #838383;
    border-radius: 14px;
}

.hContent .hLeftSide {
    display: flex;
    align-items: center;
    margin-left: 40px;
    color: white;
}

.hContent .hLeftSide p {
    color: white;
    margin: 0;
    margin-top: 6px;
}

.hIgLogoContainer {
    margin-top: 14px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
}

.fIgContact {
    text-decoration: none;
}

.hContent .hLeftSide .hMenuSelect {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #15121a;
    margin-right: 20px;
}

.hContent .hMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
}

.hContent .hMenu a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
}

.hContent .hRightSide {
    margin-right: 40px;
}

.hContent .hRightSide .hApply {
    height: 40px;
    width: 150px;
    border-radius: 14px;
    font-weight: 600;
    background: #1a1921;
    margin-right: 20px;
    color: white;
    outline: none;
    border: none;
    box-shadow: 0px 0px 6px 2px #ffa4dc;
    cursor: pointer;
}

.hContent .hRightSide .hApply:hover {
    background: #1a1921;
    box-shadow: 0px 0px 8px 4px #b7769e;
    transform: scale(1.2);
    transition: all 0.5s ease-in-out;
}

.hContent .hRightSide .hApply:not(:hover) {
    transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 740px) {
    .hContent .hLeftSide .hSelect {
        display: flex;
    }
    .hContent {
        height: auto;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 50px;
        width: 100%;
    }
    .hLogo {
        width: 80px;
    }
    .hIgLogoContainer {
        margin: 10px 0;
        display: none;
    }
    .hContent .hRightSide {
        margin: 0;
    }
    .hContent .hLeftSide {
        margin: 0;
        justify-content: center;
    }
    .hContent .hMenu {
        flex-direction: column;
        display: none;
    }
    .hContent .hMenu a {
        margin: 8px 0;
        margin-bottom: 20px;
        margin-right: 0px;
    }
    .hContent .hRightSide .hApply {
        margin-top: 10px;
        margin-right: 0;
    }
}
