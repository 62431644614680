.firstContent {
    display: flex;
    margin-top: 24px;
}

.firstContent .slogan {
    color: white;
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.firstContent .slogan .sloganContent {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 30px;
}

.firstContent .slogan .sloganContent p {
    font-size: 18px;
}

.firstContent .slogan .sloganContent .apply {
    background: #ffa4dc;
    border-radius: 14px;
    height: 44px;
    width: 150px;
    border: none;
    outline: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 18px;
    color: black;
}

.firstContent .slogan .sloganContent .apply:hover {
    background: #ffa4dc;
    box-shadow: 0px 0px 8px 4px #b7769e;
    transform: scale(1.2);
    transition: all 0.5s ease-in-out;
}

.firstContent .slogan .sloganContent .apply:not(:hover) {
    transition: all 0.5s ease-in-out;
}

.firstImageContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstImageContainer .img {
    height: 600px;
    border-radius: 14px;
}

.servicesContainer {
    background: #1a1921;
    width: 100%;
    color: white;
    margin-top: 50px;
    padding-bottom: 10px;
}

.servicesContainer .mainTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.homeContent .servicesContainer .servicesContent {
    margin-top: 120px;
}

.homeContent .servicesContainer .servicesContent .point {
    max-width: 32%;
    margin-left: 12%;
    margin-bottom: 180px;
}

.homeContent .servicesContainer .servicesContent .point h2 {
    font-size: 36px;
    font-weight: 600;
}

.homeContent .servicesContainer .servicesContent .point h3 {
    margin-left: 54px;
    margin-top: -40px;
    font-size: 24px;
    font-weight: 600;
}

.homeContent .servicesContainer .servicesContent .rightPoint {
    margin-left: 58%;
}

.secondImage {
    background-image: url("../../../images/girl-1953201_1280.jpg");
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    margin-bottom: 40px;
}

.startYourCarrer {
    font-size: 34px;
}

.apply12 {
    background: #ffa4dc;
    border-radius: 14px;
    height: 48px;
    width: 158px;
    border: none;
    outline: none;
    font-weight: 600;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 100px;
    color: black;
}

.apply12:hover {
    background: #ffa4dc;
    box-shadow: 0px 0px 8px 4px #b7769e;
    transform: scale(1.2);
    transition: all 0.5s ease-in-out;
}

.apply12:not(:hover) {
    transition: all 0.5s ease-in-out;
}

.FAContainer {
    color: #ffa4dc;
    text-align: center;
}

.FAContainer h1 {
    font-size: 56px;
    margin-bottom: 0;
}

.FAContainer p {
    color: white;
}

.FAQContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 80px;
    width: 100%;
}

@media only screen and (max-width: 1300px) {
    .firstImageContainer .img {
        height: 500px;
    }
    .firstContent .slogan .sloganContent {
        font-size: 24px;
    }
}

@media only screen and (max-width: 740px) {
    .homeContent {
        width: 100%;
    }

    .firstContent .slogan {
        width: 100%;
        margin: 0;
        justify-content: center;
    }

    .firstContent .slogan .sloganContent {
        font-size: 20px;
    }

    .firstContent {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .firstImageContainer {
        width: 80%;
    }

    .firstImageContainer .img {
        margin-top: 50px;
        width: 330px;
        height: auto;
    }

    .startYourCarrer {
        text-align: center;
        font-size: 24px;
    }

    .homeContent .servicesContainer .servicesContent .point {
        max-width: 82%;
        margin-left: 12%;
        margin-bottom: 110px;
    }

    .homeContent .servicesContainer .servicesContent .point h2 {
        font-size: 26px;
    }

    .homeContent .servicesContainer .servicesContent .point h3 {
        font-size: 16px;
    }
}
